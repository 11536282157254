.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



body.app_version .app_iphone_01 {
  width: 747px;
  position: absolute;
  right: 0%;
  z-index: 2;
  top: 19%;

}

body.app_version .app_iphone_02 {
  width: 554px;
  position: absolute;
  right: 0%;
  bottom: 17%;
  z-index: 1;
}


html {
  scroll-behavior: smooth;
}


ul {
  list-style-type: none;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.carousel__wrap {
  margin-top: 10%;
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
}

.carousel__inner {
  height: 40rem;
  position: relative;
  width: calc(90rem);
}

.carousel__container {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel__slide-list {
  height: 100%;
  left: 50%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translateX(-50%);
  width: calc(330rem);
}

.carousel__slide-item {
  display: inline-block;
  height: 30rem;
  margin: 0;
  padding: 1rem;
  position: absolute;
  transition: all 0.3s;
  width: 30rem;
}

.carousel__slide-item-img-link {
  cursor: zoom-in;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.carousel__slide-item-img-link img {
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
  width: 100%;
}
.carousel__slide-item-img-link::after {
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  content: "read more";
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  width: 100%;
}
.carousel__slide-item-img-link:hover::after {
  opacity: 1;
}
.carousel__slide-item-img-link:hover img {
  transform: scale(1.3);
}

.carousel-slide-item__body {
  bottom: -2.5rem;
  height: 10%;
  position: absolute;
}
.carousel-slide-item__body h4 {
  margin: 0.7rem 0 0;
  text-transform: uppercase;
}
.carousel-slide-item__body p {
  font-size: 1.2rem;
  line-height: 1.3;
  margin: 0.7rem 0 0;
}

.carousel__btn {
  align-items: center;
  background: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.carousel__btn--prev {
  left: -10rem;
}
.carousel__btn--next {
  right: -10rem;
}

.carousel__btn-arrow {
  border: solid black;
  border-width: 0 0.4rem 0.4rem 0;
  height: 6rem;
  padding: 3px;
  width: 6rem;
  z-index: 10;
}
.carousel__btn-arrow--left {
  transform: rotate(135deg);
}
.carousel__btn-arrow--right {
  transform: rotate(-45deg);
}

.carousel__dots {
  display: inline-block;
  left: 50%;
  margin-top: 2rem;
  position: absolute;
  transform: translateX(-50%);
}
.carousel__dots .dot {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 2rem;
  margin: 0 0.3rem;
  outline: none;
  transform: scale(0.5);
  width: 2rem;
}
.carousel__dots .dot.active {
  background: black;
}

.highlights-phone.wht .phone-holder #fon {


  background-position: center;
  background-size: contain;
}

body.app_version .how-its-work .hc h2 {

  line-height: 1px !important;
}

body.app_version .how-its-work .hc {

  padding: 25px;
 
  min-height: 300px;
  float: left;
}
input, button{
  outline: none !important;
}

.right-motor{
  width: 72%;
  margin-bottom: 26px;
}
